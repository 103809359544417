<template>
	<div>
		<div v-if="layout === 'filters'">
			<div v-if="field.type === 'multiselect' && field.choices" class="field">
				<div v-if="field.choices.length === 0" class="caption-1">
					<Label :value="field.label"/>
					<div class="paragraph">{{ $t("single_trad.Field.none") }}</div>
				</div>
				<v-expansion-panels
						v-if="field.choices.length > 0"
						v-model="panel"
						multiple
						flat
						class="cell"
				>
					<v-expansion-panel class="cell" :isActive="true">
						<v-expansion-panel-header
								class="ma-0 pa-0 pb-1"
								style="max-height: 25px"
								justify="space-between"
								align="center"
								color="label--text"
						>
							<div class="sub-title-2">{{ field.label | firstCapital }}</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content eager>
							<NestedFilter v-model="new_val" :items="field.choices"/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>

			<div v-else-if="field.type === 'select' && field.choices" class="field">
				<div v-if="field.choices.length === 0" class="caption-1">
					<Label :value="field.label"/>
					<div class="paragraph">{{ $t("single_trad.Field.none") }}</div>
				</div>
				<v-expansion-panels
						v-if="field.choices.length > 0"
						v-model="panel"
						multiple
						flat
						class="cell"
				>
					<v-expansion-panel class="cell" :isActive="true">
						<v-expansion-panel-header
								class="ma-0 pa-0 pb-1"
								style="max-height: 25px"
								justify="space-between"
								align="center"
								color="label--text"
						>
							<div class="sub-title-2">{{ field.label | firstCapital }}</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content eager>
							<Checkbox
									v-for="(choice, i) in field.choices"
									v-model="new_val[choice.id]"
									:label="choice"
									:layout="layout"
									:key="i"
									class="mb-1"
							/>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>

			<div
					v-else-if="
				          field.type === 'string' ||
				          field.type === 'email' ||
				          field.type === 'phone'
				        "
			>
				<Label :value="field.label"/>
				<TextField v-model="new_val"/>
			</div>
			<div v-else-if="field.type === 'integer' || field.type === 'float'">
				<Label :value="field.label"/>
				<v-row no-gutters align="center">
					<v-col>
						<TextField v-model="new_val.min" placeholder="Min"/>
					</v-col>

					<v-col cols="auto" class="px-2 paragraph">{{
						$t("global.action.to")
						}}
					</v-col>
					<v-col>
						<TextField v-model="new_val.max" placeholder="Max"/>
					</v-col>
				</v-row>
			</div>
		</div>

		<v-hover v-else v-slot:default="{ hover }">
			<v-row
					@keyup.esc="!loading && mode !== 'creation' && mode !== 'multiple_creation' ? change ? closeField() : (edit = !edit) : ''"
					@keyup.enter="layout !== 'files' ? field.type !== 'html' && change && !loading && mode !== 'multiple_creation' && mode !== 'creation' ? saveItem() : '' : ''"
					@dblclick="edit ? '' : mode !== 'creation' && mode !== 'multiple_creation' && mode !== 'readonly' && field.type !== 'image' && field.type !== 'id_media' ? change ? closeField() : (edit = !edit) : ''"
					class="mx-0 transition-cubic rounded-lg"
					:class="
				          (hover || menu_hover) &&
				          field.type !== 'id_media' &&
				          mode !== 'creation' &&
				          mode !== 'multiple_creation' &&
				          !field.read_only &&
				          mode !== 'readonly' &&
				          mode !== 'fast-edition'
				            ? 'cell2'
				            : ''
				        "
			>
				<v-col
						style="min-height: 63px"
						:class="
				            mode === 'creation' ||
				            mode === 'multiple_creation' ||
				            mode === 'fast-edition'
				              ? ''
				              : mode === 'readonly'
				              ? ''
				              : layout === 'files'
				              ? 'px-0'
				              : field.type === 'id_media'
				              ? 'pa-0'
				              : 'pr-1'
				          "
						:style="
				            !field.read_only && mode === 'edition' && field.type !== 'id_media'
				              ? { maxWidth: 'calc(100% - 40px)' }
				              : {}
				          "
				>
					<v-row class="mx-0" no-gutters>
						<v-col cols="12">
							<Label
									v-if="field.label && field.type !== 'id_media'"
									:small="true"
									:value="field.label"
									:required="
					                  field.required &&
					                  field.type !== 'multiselect' &&
					                  mode !== 'readonly' &&
					                  mode !== 'edition'
					                "
									@dblclick="
					                  mode !== 'creation' &&
					                  mode !== 'multiple_creation' &&
					                  mode !== 'readonly' &&
					                  field.type !== 'image'
					                    ? change
					                      ? closeField()
					                      : (edit = !edit)
					                    : ''
					                "
									@hover="(e) => (menu_hover = e)"
							/>
						</v-col>

						<v-col
								v-if="
					                edit &&
					                !field.read_only &&
					                mode !== 'readonly' &&
					                layout !== 'files'
					              "
								class="pt-0 paragraph"
								style="min-width: 100px"
						>
							<TextField
									v-model="new_val"
									clearable
									v-if="
					                  field.type === 'string' ||
					                  field.type === 'integer' ||
					                  field.type === 'float' ||
					                  field.type === 'email' ||
					                  field.type === 'phone'
					                "
									:type="field.type"
							/>
							<DurationField
									v-else-if="field.type === 'duration'"
									v-model="new_val"
									:edit="true"
									@close="closeField()"
									@save=""
									:mode="mode"
							/>
							<TextAreaField
									v-else-if="field.type === 'text'"
									v-model="new_val"
							/>
							<Select
									v-model="new_val"
									v-else-if="field.type === 'select'"
									:items="field.choices"
									clearable
									returnValue
									itemText="display_name"
									:attach="false"
							/>
							<Select
									v-model="new_val"
									v-else-if="field.type === 'choice' || field.type === 'multichoice'"
									itemVal="value"
									:items="field.choices"
									clearable
									returnValue
									:multiple="field.type === 'multichoice'"
									:attach="false"
							/>
							<MultiSelect
									v-model="new_val"
									v-else-if="field.type === 'multiselect' && field.choices"
									:choices="field.choices"
									:label="field.label"
									:edit="edit"
									:attach="false"
							/>
							<TimePicker
									v-model="new_val"
									v-else-if="field.type === 'date' || field.type === 'datetime'"
									:outlined="true"
									:clearable="true"
							/>
							<Checkbox
									v-model="new_val"
									v-else-if="field.type === 'boolean'"
							/>
							<HtmlArea
									v-model="new_val"
									v-else-if="field.type === 'html'"
									:edit="true"
									:pevents="pevents"
							/>
							<ChangeField
									v-else-if="field.type === 'object'"
									v-model="new_val"
							/>
							<ImageField
									v-model="new_val"
									v-else-if="field.type === 'image'"
									:edit="edit"
									:path="path"
									:fieldName="fieldName"
									:creation="mode === 'creation' || mode === 'multiple_creation'"
									@ready="$emit('fileField', fieldName)"
							/>
							<FileField
									v-model="new_val"
									v-else-if="field.type === 'file'"
									@ready="$emit('fileField', fieldName)"
									clearable
							/>
							<ColorField
									v-model="new_val"
									v-else-if="field.type === 'color'"
							/>
							<IconField
									v-else-if="field.type === 'icon'"
									v-model="new_val"
									edit
							/>
						</v-col>

						<v-col
								v-if="!edit || field.read_only"
								:class="field.read_only ? 'cell4--text' : ''"
								class="paragraph"
								style="min-width: 100px"
						>
							<div
									v-if="
					                  field.type === 'string' ||
					                  field.type === 'integer' ||
					                  field.type === 'float' ||
					                  field.type === 'email' ||
					                  field.type === 'phone' ||
					                  field.type === 'text'
					                "
							>
								{{ new_val }}
							</div>
							<div
									v-else-if="field.type === 'date' || field.type === 'datetime'"
							>
								{{ new_val | toLocalDate }}
							</div>
							<MultiSelect
									v-model="new_val"
									v-else-if="field.type === 'multiselect'"
									:choices="field.choices"
									:label="field.label"
							/>
							<div
									v-else-if="field.type === 'select' && new_val"
									:style="
					                  field.choices.find((e) => e.id === new_val.id)
					                    ? {}
					                    : { fontStyle: 'italic' }
					                "
									:class="
					                  field.choices.find((e) => e.id === new_val.id)
					                    ? ''
					                    : 'warning--text'
					                "
							>
								{{ field.choices.find((e) => e.id === new_val.id) ? field.choices.find((e) => e.id === new_val.id).display_name : new_val.display_name }}
							</div>
							<div v-else-if="field.type === 'choice' && new_val">
								{{ field.choices.find((e) => e.value === new_val).display_name }}
							</div>
							<Checkbox
									v-model="new_val"
									v-else-if="field.type === 'boolean'"
									readonly
							/>
							<HtmlArea
									v-else-if="field.type === 'html'"
									:edit="false"
									:content="new_val"
									:full="true"
							/>
							<ChangeField
									v-else-if="field.type === 'object'"
									v-model="new_val"
							/>
							<ImageField
									v-else-if="field.type === 'image'"
									v-model="new_val"
									:path="path"
									:src="new_val"
									:fieldName="fieldName"
									@ready="$emit('fileField', fieldName)"
									class="rounded-lg overflow-hidden mr-2"
									:style="{ height: parent_width - 8 + 'px' }"
							/>
							<IDMediaField
									v-else-if="field.type === 'id_media'"
									v-model="new_val"
									:path="path"
									:fieldName="fieldName"
							/>
							<v-btn
									v-else-if="(field.type === 'file' || field.type === 'url') && new_val"
									dark
									small
									class="mt-2 primary elevation-2"
									:href="backend_url + new_val"
									target="_blank"
							>
								<v-icon size="18" left>mdi-download</v-icon>
								{{ $tc("global.action.download", 1) }}
							</v-btn>
							<div
									v-else-if="field.type === 'color'"
									class="rounded-circle"
									:style="{ backgroundColor: new_val }"
									style="width: 20px; height: 20px"
							></div>
							<IconField v-else-if="field.type === 'icon'" v-model="new_val"/>
							<DurationField
									v-else-if="field.type === 'duration'"
									v-model="new_val"
									:mode="mode"
							/>
							<ProgressField
									v-else-if="field.type === 'progress'"
									v-model="new_val"
									:value="new_val"
							/>
							<div v-else>{{ $t("single_trad.Table.no_data") }}</div>
						</v-col>

						<v-col
								cols="auto"
								class="pl-1"
								v-if="(field.type === 'select' || field.type === 'multiselect') && !field.read_only && edit"
						>
							<Button
									icon
									iconVal="mdi-plus"
									color="primary"
									iconColor="primary"
									small
									carre
									iconSize="22"
									:click="() => openForm()"
									:toolTip="$t('single_trad.EntityField.create_item', {type: field.label})"
									:loading="plus_loading"
									:disabled="!fast_creation"
							/>
						</v-col>
					</v-row>
				</v-col>

				<v-col
						v-if="
				            mode !== 'creation' &&
				            mode !== 'multiple_creation' &&
				            mode !== 'readonly' &&
				            mode !== 'fast-edition' &&
				            layout !== 'files' &&
				            field.type !== 'image' &&
				            field.type !== 'id_media'
				          "
						cols="auto"
						class="pl-0 d-flex"
						style="flex-direction: column; justify-content: space-between;"
				>
					<v-row no-gutters>
						<v-col style="min-height: 28px; min-width: 28px">
							<v-fade-transition>
								<v-btn
										v-if="!field.read_only"
										v-show="hover || menu_hover || edit"
										@click="change ? closeField() : (edit = !edit)"
										icon
										small
										:color="edit ? '' : 'primary'"
										:loading="loading"
								>
									<v-icon size="20">{{
										edit ? "mdi-close" : "mdi-pencil"
										}}
									</v-icon>
								</v-btn>
							</v-fade-transition>
						</v-col>
					</v-row>
					<v-row no-gutters align="end">
						<v-col style="height: 28px">
							<v-fade-transition>
								<v-btn
										v-if="!field.read_only"
										v-show="change"
										@click="saveItem()"
										icon
										small
										color="success"
										:loading="loading"
								>
									<v-icon size="20">mdi-check</v-icon>
								</v-btn>
							</v-fade-transition>
						</v-col>
					</v-row>
				</v-col>
				<v-col
						v-if="layout === 'files' && field.type !== 'image' && edit"
						cols="auto"
						class="px-0 py-2 d-flex"
						style="flex-direction: column; justify-content: space-between"
				>
					<v-row no-gutters justify="center">
						<v-col
								v-if="!loading"
								@keyup.enter="change ? saveItem() : closeField()"
						>
							<TextAreaField
									v-model="new_val"
									styles="min-width: 28px;"
									:grow="true"
									:noEnter="true"
							/>
						</v-col>

						<v-col v-else cols="12">
							<v-progress-circular
									indeterminate
									color="primary"
							></v-progress-circular>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-hover>

		<Dialog
				v-model="dialog"
				:title="
			        $t('single_trad.FieldGroup.creation_title', { type: field.label })
			      "
				@close="closeDialog()"
		>
			<template v-slot:content>
				<FieldGroup
						v-if="Object.keys(new_form_fields).length > 0"
						v-model="new_form"
						mode="creation"
						:fields="new_form_fields"
						:path="resource"
						:pevents="pevents"
						:pathId="pathId"
						@created="(e) => closeDialog(e)"
						@newItemCreated="(e) => new_form_fields[e.field].choices.push(e.item)"
				/>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: "Field",
		props: [
			"value",
			"field",
			"layout",
			"mode",
			"oldVal",
			"pevents",
			"fieldName",
			"path",
			"pathId",
			"extras",
		],
		components: {
			MultiSelect: () => import("@/components/fields/MultiSelect.vue"),
			NestedFilter: () => import("@/components/ui/NestedFilter.vue"),
			FieldGroup: () => import("@/components/FieldGroup.vue"),
			Dialog: () => import("@/components/ui/Dialog.vue"),
			Label: () => import("@/components/ui/Label.vue"),
			TextField: () => import("@/components/fields/TextField.vue"),
			TextAreaField: () => import("@/components/fields/TextAreaField.vue"),
			Select: () => import("@/components/fields/Select.vue"),
			TimePicker: () => import("@/components/fields/TimePicker.vue"),
			HtmlArea: () => import("@/components/fields/HtmlArea.vue"),
			ChangeField: () => import("@/components/fields/ChangeField.vue"),
			ImageField: () => import("@/components/fields/ImageField.vue"),
			IDMediaField: () => import("@/components/fields/IDMediaField.vue"),
			Checkbox: () => import("@/components/fields/Checkbox.vue"),
			FileField: () => import("@/components/fields/FileField.vue"),
			ColorField: () => import("@/components/fields/ColorField.vue"),
			IconField: () => import("@/components/fields/IconField.vue"),
			DurationField: () => import("@/components/fields/DurationField.vue"),
			ProgressField: () => import("@/components/fields/ProgressField.vue"),
			Button: () => import("@/components/ui/Button.vue"),
		},
		data() {
			return {
				new_form: {},
				new_form_fields: {},
				arrayfalse: [],
				arraytrue: [],
				dialog: false,
				resource: null,
				panel: [0],
				edit: this.mode === "creation" || this.mode === "multiple_creation",
				change: false,
				menu_hover: false,
				loading: false,
				fast_creation: false,
				plus_loading: false,
				parent_width: 0,
			};
		},
		computed: {
			new_val: {
				get() {
					if (
						(this.field.type === "date" || this.field.type === "datetime") &&
						this.value === null
					) {
						return "";
					} else {
						return this.value;
					}
				},
				set(val) {
					this.$emit("input", val);

					this.$emit("change");

					if (this.layout !== "filters" && this.field.type !== "image") {
						if (
							this.oldVal !== null &&
							this.oldVal !== "" &&
							typeof this.oldVal !== "undefined"
						) {
							if (JSON.stringify(val) !== JSON.stringify(this.oldVal)) {
								this.change = true;
								this.pevents.notify("child-changed", this.fieldName, true);
							} else {
								this.change = false;
								this.pevents.notify("child-changed", this.fieldName, false);
							}
						} else {
							if (val !== null && val !== "" && typeof val !== "undefined") {
								this.change = true;
								this.pevents.notify("child-changed", this.fieldName, true);
							} else {
								this.change = false;
								this.pevents.notify("child-changed", this.fieldName, false);
							}
						}
					}
				},
			},
			backend_url() {
				return this.$store.getters["config/backend_url"];
			},
		},
		watch: {
			mode(val) {
				if (val === "creation" || val === "multiple_creation") {
					this.edit = true;
				}
				if (val === "readonly") {
					this.edit = false;
				}
			},
			edit(val) {
				if (
					this.resource === null &&
					val &&
					(this.field.type === "multiselect" || this.field.type === "select")
				)
					this.loadForm();
			},
		},
		methods: {
			closeDialog(item) {
				if (item) {
					if (this.field.type === "multiselect") {
						if (this.new_val && this.new_val.length > 0) {
							this.new_val.push(item);
						} else {
							this.new_val = [item];
						}
					} else {
						this.new_val = {id: item.id, display_name: item.display_name};
					}

					this.$emit("newItemCreated", item);
					this.change = true;
				}

				this.new_form = {};
				this.new_form_fields = {}
				this.dialog = false;
			},
			closeField() {
				this.edit = !this.edit;
				this.new_val = this.oldVal;
				this.change = false;
				this.pevents.notify("child-closed", this.fieldName, this.pathId);
			},
			computeLink(endpoint) {
				let res = [];

				if (endpoint) {
					for (let i of endpoint.split("/")) {
						if (i.match(":")) {
							let vat = i.replace(":", "");
							res.push(this.$route.params[vat]);
						} else {
							res.push(i);
						}
					}
				}

				return res.join("/");
			},
			loadForm() {
				this.plus_loading = true;
				this.resource = this.computeLink(this.field.resource.endpoint);

				this.$wsc.getOptions(
					this.$route.params,
					this.resource,
					this.resource,
					(success) => {
						if (success.methods.includes("create")) {
							this.fast_creation = true;
						}

						this.plus_loading = false;
					},
					(fail) => {
						this.plus_loading = false;
					},
					{},
					false,
					true
				);
			},
			openForm() {
				this.$wsc.getOptions(this.$route.params, this.resource, this.resource, (success) => {
					this.new_form_fields = this.$tools.filterObject(
						success.fields,
						(e) => !e.read_only
					);
					this.dialog = true;
				}, (fail) => {
				});
			},
			saveItem() {
				this.loading = true;
				this.pevents.notify("child-saving", true);

				let data = {};
				data[this.fieldName] = this.new_val;

				if (this.field.type === "file") {
					this.$wsc.toFormData(data, (succ, header) => {
						this.$wsc.patchItem(
							this.path,
							this.pathId ? this.pathId : this.$route.params[this.$route.name],
							succ,
							(suc) => {
								this.pevents.notify("child-saved", this.fieldName, suc);
								this.pevents.notify("child-saving", false);
								this.change = false;
								this.edit = false;
								this.loading = false;
								this.pevents.notify('update', suc)
							},
							(fail) => {
								this.loading = false;
							},
							header
						);
					});
				} else {
					if (this.extras && this.fieldName === "value") {
						data[this.fieldName] = "" + this.new_val;
					}

					this.$wsc.patchItem(
						this.path,
						this.pathId ? this.pathId : this.$route.params[this.$route.name],
						data,
						(success) => {
							this.pevents.notify("child-saved", this.fieldName, success);
							this.pevents.notify("child-saving", false);
							this.change = false;
							this.edit = false;
							this.loading = false;
							this.pevents.notify('update', suc)
						},
						(fail) => {
							this.loading = false;
						}
					);
				}
			},
			setUpHooks() {
				if (this.layout !== "filters") {
					this.pevents.wait("close", () => {
						if (this.edit === true) {
							this.edit = false;
							this.change = false;
							this.new_val = JSON.parse(JSON.stringify(this.oldVal));
						}
					});

					this.pevents.wait("close-fast", () => {
						this.change = false;

						if (typeof this.oldVal !== "undefined")
							this.new_val = JSON.parse(JSON.stringify(this.oldVal));
					});

					this.pevents.wait("close-saved", () => {
						this.edit = false;
						this.change = false;
					});

					this.pevents.wait("edit-true", (files) => {
						this.edit = files.find((f) => f.id === this.pathId);
					});
				}
			}
		},
		created() {
			if (
				this.resource === null &&
				this.mode !== "edition" &&
				(this.field.type === "multiselect" || this.field.type === "select")
			)
				this.loadForm();
			this.setUpHooks();
		},
		mounted() {
			this.$emit("ready");
		},
	};
</script>

<style scoped>
	.field >>> .v-expansion-panel--active > .v-expansion-panel-header {
		height: 30px !important;
	}
</style>
